<template>
  <div id="news">
    <div id="sidebar" :class="{ active: isactive }">
      <div class="toggle-btn" @click="toggleSidebar">
        <b-icon icon="bell" class="news-icon"></b-icon>
      </div>
      <div class="list">
          <b-icon icon="list" @click="toggleSidebar"></b-icon>
        <h3 class="sidebar-Title">Новости</h3>
        <p>сервиса Faktura.PAY</p>
      </div>
      <div class="list-news">
          <h4>Конструктор чат-ботов для Telegram</h4>
          <p>Сервис который позволяет ускорить передачу коммерческой информации, что позволит вам сократить ошибки а также улучшить эффективность вашей бухгалтерии</p>
          <a href="#!">{{translation.translate( "Modules", "btn-more-info")}}</a> <span>2 март, 2021 года</span>
      </div>
      <div class="list-news">
          <h4>Конструктор чат-ботов для Telegram</h4>
          <p>Сервис который позволяет ускорить передачу коммерческой информации, что позволит вам сократить ошибки а также улучшить эффективность вашей бухгалтерии</p>
          <a href="#!">{{translation.translate( "Modules", "btn-more-info")}}</a> <span>2 март, 2021 года</span>
      </div>
      <div class="list-news">
          <h4>Конструктор чат-ботов для Telegram</h4>
          <p>Сервис который позволяет ускорить передачу коммерческой информации, что позволит вам сократить ошибки а также улучшить эффективность вашей бухгалтерии</p>
          <a href="#!">{{translation.translate( "Modules", "btn-more-info")}}</a> <span>2 март, 2021 года</span>
      </div>
    </div>
  </div>
</template>

<script defer>
import translate from "../../translation/translate";

export default {
  name: "news",
  components: {},
  data() {
    return {
      translation: translate,
      isactive: null,
    };
  },
  methods: {
    toggleSidebar() {
      this.isactive = !this.isactive;
    },
  },
};
</script>

<style></style>
